import { EventBus } from "@/services/event-bus";
import { EventType } from "mitt";
import { Component, Vue } from "vue-property-decorator";

export type EventHandler = (...args: any[]) => void;
export type EventItem = Record<string, EventHandler>;

@Component
export default class EventMixin extends Vue {
  arEvents: EventItem[] = [];

  addEvent(sName: string, handler: EventHandler): void {
    this.arEvents.push({ [sName]: handler });
  }

  emit(sName: string, handler?: Record<EventType, unknown> | any): void {
    EventBus.emit(sName, handler);
  }

  registerEvents() {
    if (!this.arEvents.length) {
      return;
    }

    this.$_.forEach(this.arEvents, (obEvent) => {
      this.$_.forEach(obEvent, (obVal: EventHandler, sKey: string) => {
        EventBus.off(sKey, obVal);
        EventBus.on(sKey, obVal);
      });
    });
  }

  unregisterEvents() {
    if (!this.arEvents.length) {
      return;
    }

    this.$_.forEach(this.arEvents, (obEvent) => {
      this.$_.forEach(obEvent, (obVal: EventHandler, sKey: string) => {
        EventBus.off(sKey, obVal);
      });
    });
  }
}
